import Bugsnag from '@bugsnag/js';

/**
 * fetcher function for use with SWF https://swr.vercel.app/docs/options
 */
export default async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  let errorReport: { payload: any; status: any };
  try {
    const timeout = 5000;
    const res = await fetchWithTimeout(input, init, timeout);

    if (!res || !res.ok) {
      const error = new Error(
        res
          ? 'An error occurred while fetching the data.'
          : `Request timed out after ${timeout}ms`,
      );
      errorReport = {
        payload: await res?.json(),
        status: res?.status,
      };
      throw error;
    }
    return res.json();
  } catch (error: any) {
    Bugsnag.notify(error, (event) => {
      event.severity = 'error';
      event.request.url = typeof input === 'string' ? input : input.url;
      event.request.status = errorReport.status;
      event.addMetadata('payload', errorReport.payload);
    });
    throw error;
  }
}

/**
 * This adds a timeout via AbortController. Note that this is available
 * in NodeJs 16+ for LTS versions.
 * Throws
 * AbortError: The user aborted a request.
 * on timeout
 */
async function fetchWithTimeout(
  input: RequestInfo,
  init?: RequestInit,
  timeout = 5000,
) {
  const requestController = new AbortController();
  const requestTimeoutObserver = setTimeout(
    () => requestController.abort(),
    timeout,
  );
  const response = await fetch(input, {
    ...init,
    signal: requestController.signal,
  });
  clearTimeout(requestTimeoutObserver);

  return response;
}
