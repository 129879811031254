export const getBaseUrlFromUrl = (url: string): string => {
  const urlObject = new URL(url);
  return urlObject.origin;
};

export const getQueryParamsFromUrl = (url: string): Record<string, string> => {
  const urlObject = new URL(url);
  return Object.fromEntries(urlObject.searchParams);
};

export const getHashFromUrl = (url: string): string => {
  const urlObject = new URL(url);
  return urlObject.hash.substring(1);
};

export const getHashParamsFromUrl = (url: string): Record<string, string> => {
  const urlObject = new URL(url);
  const hash = urlObject.hash.substring(1);
  const pairs = hash.split(';');

  return Object.fromEntries(pairs.map((pair) => pair.split('=')));
};

export const encodeUrlFromParts = (
  baseUrl: string,
  queryParams: Record<string, string>,
  hash?: string,
) => {
  const query = new URLSearchParams(queryParams).toString();
  const hashParams = hash ? `#${hash}` : '';
  // Note the affiliate url cannot have a trailing slash
  // so we omit that.
  const url = `${baseUrl}?${query}${hashParams}`;
  return url;
};
