import { styled } from '@mui/material/styles';

import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import { SPACE2, SPACE4, SPACE6, SPACE9 } from 'lib/constants/layout';
import Grid from '@mui/material/Grid';
import { PhoneLink } from 'components/PhoneLink/PhoneLink';
import { TrackingEvents } from 'lib/tracking/events';
import { track } from 'lib/tracking/helper';

const { colors } = finanzcheck;
const Wrapper = styled('div')`
  box-shadow: inset 0 0.25em 0.25em -0.25em rgba(0, 0, 0, 0.3);
  padding: ${SPACE6} 0;
  text-align: center;
  width: 100%;
`;

const Heading = styled('span')(({ theme }) => ({
  // @ts-ignore:next-line
  color: theme.palette.text.blue,
  display: 'block',
  fontWeight: 'bold',
}));

const PhoneNumber = styled(PhoneLink)(() => ({
  alignItems: 'center',
  border: `2px solid ${colors.neutral.grey100}`,
  display: 'inline-flex',
  fontWeight: 'bold',
  height: SPACE9,
  margin: `${SPACE2} 0`,
  padding: `0 ${SPACE4}`,
  textDecoration: 'none',
}));

const Hours = styled('span')`
  display: block;
`;

interface AccordionProps {
  phone: string;
}

/**
 * Collapsible customer service info available on mobile only.
 */
export const AccordionContent: React.FC<AccordionProps> = ({ phone }) => {
  return (
    <Wrapper>
      <Grid container>
        <Grid item>
          <Heading>Persönliche &amp; kostenfreie Beratung</Heading>
          <PhoneNumber
            phone={phone}
            onClick={() => {
              // mobile only, desktop tracking is in HeaderContainer.jsx
              track(TrackingEvents.PHONEBOX_BUTTON_ONINTERACT);
            }}
          >
            {phone}
          </PhoneNumber>
          <Hours>Täglich 8 bis 20 Uhr</Hours>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
