import {
  DEFAULT_AMOUNT,
  DEFAULT_PURPOSE,
  DEFAULT_TERM,
} from 'lib/constants/offerFilter';
import { DEFAULT } from 'lib/constants/registrationRoutes';
import { IPgPageBuilderFields } from 'types/generated/contentful';

export const getDefaultPageConfig = () => {
  return {
    term: DEFAULT_TERM,
    amount: DEFAULT_AMOUNT,
    purpose: DEFAULT_PURPOSE,
    includeSEO: false,
    markupSchema: 'article',
    minimizeHeaderAndFooter: false,
    registrationRoute: DEFAULT,
    isMainSEAPage: false,
  };
};

export interface IContentfulPageConfig {
  userTerm: number;
  includeSEO: boolean;
  userAmount: number;
  userPurpose: string;
  markupSchema: string;
  minimizeHeaderAndFooter: boolean;
  userOverrideDefaultTerm: boolean;
  userOverrideDefaultAmount: boolean;
  userOverrideDefaultPurpose: boolean;
  registrationRoute: string;
  userOverrideDefaultRegistrationRoute: boolean;
}

export const getSimplifiedPageConfig = (pageConfig: IContentfulPageConfig) => {
  const {
    userTerm,
    includeSEO,
    userAmount,
    userPurpose,
    markupSchema,
    minimizeHeaderAndFooter,
    registrationRoute,
    userOverrideDefaultTerm,
    userOverrideDefaultAmount,
    userOverrideDefaultPurpose,
    userOverrideDefaultRegistrationRoute,
  } = pageConfig;

  return {
    term: userOverrideDefaultTerm ? userTerm : DEFAULT_TERM,
    amount: userOverrideDefaultAmount ? userAmount : DEFAULT_AMOUNT,
    purpose: userOverrideDefaultPurpose ? userPurpose : DEFAULT_PURPOSE,
    includeSEO,
    markupSchema,
    minimizeHeaderAndFooter,
    registrationRoute: userOverrideDefaultRegistrationRoute
      ? registrationRoute
      : DEFAULT,
  } as IPageConfig;
};

export const getCustomFormConfig = (page: IPgPageBuilderFields) => {
  const { url } = page;

  if (url === '/lp/kreditvergleich-dac/') {
    return 'ddf_marketing';
  }

  return '';
};

export const getUrlPageConfig = (page: IPgPageBuilderFields) => {
  const { url } = page;

  // These are special and get a separate legal text footer.
  // We should think about porting this flag into Contentful.
  const mainSEAPageUrls = [
    '/lp/kreditvergleich/',
    '/lp/kreditvergleich-dac/',
    '/lp/autokredit/',
    '/lp/banken/',
    '/lp/umschuldung/',
    '/lp/online-kreditvergleich/',
    '/lp/kredite/',
    '/lp/autokredit-direkt/',
    '/lp/umschuldung-direkt/',
    '/lp/test/', // This is a test page just to have it the same as SEA
  ];

  const isMainSEAPage = mainSEAPageUrls.includes(url);

  return {
    isMainSEAPage,
  };
};

export interface IPageConfig {
  amount: number;
  term: number;
  purpose: string;
  includeSEO: boolean;
  markupSchema: string;
  minimizeHeaderAndFooter: boolean;
  registrationRoute: string;
  isMainSEAPage: boolean;
  formConfig?: string;
}

export const getPageConfig = (page: IPgPageBuilderFields) => {
  const contenfulPageConfig: IContentfulPageConfig =
    page.pageConfig as IContentfulPageConfig;

  const defaultPageConfig = getDefaultPageConfig();
  const simplifiedContentfulPageConfig =
    getSimplifiedPageConfig(contenfulPageConfig);
  const urlPageConfig = getUrlPageConfig(page);

  return {
    ...defaultPageConfig,
    ...simplifiedContentfulPageConfig,
    ...urlPageConfig,
  } as IPageConfig;
};
