import { PHONE_INTERNATIONAL_AREA_CODE } from 'lib/constants/address';

interface PhoneLinkProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  phone: string;
  title?: string;
}

export const PhoneLink: React.FC<PhoneLinkProps> = ({
  children,
  className,
  onClick,
  phone,
  title,
}) => {
  const phoneLink =
    PHONE_INTERNATIONAL_AREA_CODE + phone.replace(/\s/g, '').substring(1);
  return (
    <a
      className={className}
      href={`tel:${phoneLink}`}
      title={title}
      onClick={onClick}
      role="button"
    >
      {children}
    </a>
  );
};
