import { styled } from '@mui/material/styles';

import { SPEED_MEDIUM, TIMING_FUNCTION } from '../../lib/constants/layout';

interface AccordionProps {
  height?: string;
}

export const Accordion = styled('div')<AccordionProps>(({ height }) => ({
  height: height,
  overflow: 'hidden',
  transform: 'translate3d(0, 0, 0)',
  transition: `height ${SPEED_MEDIUM} ${TIMING_FUNCTION}`,
}));
