import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { Accordion } from './Accordion';

/**
 * An animated accordion component. When not opened, the content of the accordion is removed from the DOM.
 *
 * @param {Object} props         - Both `children` and `isOpen` are required
 * @param {React} props.children - The accordion content
 * @param {boolean} props.isOpen - A boolean indicating if the accordion should be open
 */

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (props) => props !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
  ariaHidden: !isVisible,
}));

interface AccordionProps {
  children?: React.ReactNode;
  isOpen?: boolean;
}

export const AccordionContainer: React.FC<AccordionProps> = (props) => {
  const [isMounted, setMounted] = useState(false);
  const [isInDOM, setInDOM] = useState(!!props.isOpen);
  const [isVisible, setVisible] = useState(!!props.isOpen);

  const childElement = useRef(null);
  const isOpen = useRef(props.isOpen);
  let height;

  if (isMounted) {
    height = `${
      childElement.current && isVisible
        ? childElement.current['scrollHeight']
        : 0
    }px`;
    const justToggled = props.isOpen !== isOpen.current;

    if (justToggled) {
      if (isOpen.current) {
        setVisible(false);
      } else {
        if (!isInDOM) {
          setInDOM(true);
        }

        requestAnimationFrame(() => isOpen.current && setVisible(true));
      }
    }
  } else if (props.isOpen) {
    // If the accordion was not yet mounted and set to be opened, we don't want to animate it
    height = 'auto';
  }

  // We set if that the component has been mounted
  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  }, [isMounted]);

  // We update the isOpen ref value after checking if it changed
  isOpen.current = props.isOpen;

  return (
    isInDOM && (
      <StyledAccordion
        isVisible={isVisible}
        height={height}
        ref={childElement}
        role="presentation"
      >
        {props.children}
      </StyledAccordion>
    )
  );
};
