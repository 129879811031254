const handleCookiePrefsClick = () => window?.OneTrust?.ToggleInfoDisplay();

export const footerNavigation = {
  columns: [
    {
      headline: 'Unsere Vergleiche',
      list: [
        {
          label: 'Kreditvergleich',
          url: '/kreditvergleich/',
        },
        {
          label: 'Kredit',
          url: '/kredit/',
        },
        {
          label: 'Autokredite',
          url: '/autokredit/',
        },
        {
          label: 'Ratenkredite',
          url: '/ratenkredit/',
        },
        {
          label: 'Umschuldungen',
          url: '/umschuldung/',
        },
        {
          label: 'Baufinanzierungen',
          url: '/baufinanzierung/',
        },
      ],
    },
    {
      headline: 'Unternehmen',
      list: [
        {
          label: 'Über uns',
          url: '/uber-uns/',
        },
        {
          label: 'Jobs',
          url: '/jobs/',
        },
        {
          label: 'Presse',
          url: '/presse/',
        },
        {
          label: 'FAQ',
          url: '/faq/',
        },
      ],
    },
    {
      headline: 'Kontakt',
      list: [
        {
          label: 'Partnerprogramm',
          url: 'https://affiliate.finanzcheck.de/',
        },
        {
          label: 'finanzcheckPRO - B2B Partnerlösung',
          url: 'https://www.finanzcheckpro.de/',
        },
        {
          label: 'Telefonberatung',
          url: '/uber-uns/telefonberatung/',
        },
        {
          label: 'AGB',
          url: '/uber-uns/agb/',
        },
        {
          label: 'Datenschutz',
          url: '/uber-uns/datenschutz/',
        },
        {
          label: 'Cookie Einstellungen',
          handle: handleCookiePrefsClick,
        },
        {
          label: 'Impressum',
          url: '/uber-uns/impressum/',
        },
        {
          label: 'Kontakt',
          url: 'https://finanzcheck.zendesk.com/hc/de/requests/new?ticket_form_id=7865093897501',
        },
        {
          label: 'DSA Beschwerdeverfahren',
          url: '/uber-uns/dsa-beschwerdeverfahren/',
        },
      ],
    },
  ],
};
