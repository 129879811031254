import { memo } from 'react';

import { FooterTemplate } from '../FooterTemplate/FooterTemplate';
import { MinimalLinks } from './MinimalLinks';
import { LegalTextSea } from './LegalTextSea';

interface FooterMinimalProps {
  datenschutzUrl?: string;
  openLinksInNewTab?: boolean;
  showLegalText?: boolean;
  mt?: number;
}

/**
 * Footer component containing only the minimal links
 *
 * @param {Object}  props
 * @param {boolean} [props.openLinksInNewTab=false] - Indicates if the links should be open in a new tab (or window)
 */
export const FooterMinimal: React.FC<FooterMinimalProps> = memo(
  ({ openLinksInNewTab, showLegalText, mt, datenschutzUrl }) => (
    <FooterTemplate
      mt={mt}
      RightContent={
        <MinimalLinks
          target={openLinksInNewTab ? '_blank' : '_self'}
          datenschutzUrl={datenschutzUrl}
        />
      }
      LegalText={showLegalText && <LegalTextSea />}
      datenschutzUrl={datenschutzUrl}
    />
  ),
);

FooterMinimal.displayName = 'FooterMinimal';
