import { css } from '@emotion/react';

import { finanzcheck } from '@finanzcheck/teal-mui-theme';

// -- Spacing --

const { colors } = finanzcheck;
export const SPACE2_RAW = 8;
export const SPACE3_RAW = 12;

export const SPACE = '4px';
export const SPACE2 = `${SPACE2_RAW}px`;
export const SPACE3 = `${SPACE3_RAW}px`;
export const SPACE4 = '16px';
export const SPACE5 = '20px';
export const SPACE6 = '24px';
export const SPACE8 = '32px';
export const SPACE9 = '36px';
export const SPACE10 = '40px';
export const SPACE12 = '48px';
export const SPACE13 = '52px';
export const SPACE14 = '56px';
export const SPACE16 = '64px';
export const SPACE18 = '72px';
export const SPACE20 = '80px';

// -- Animations --

export const SPEED_INSTANT_RAW = 50;
export const SPEED_FAST_RAW = 150;
export const SPEED_MEDIUM_RAW = 300;

export const SPEED_INSTANT = `${SPEED_INSTANT_RAW}ms`;
export const SPEED_FAST = `${SPEED_FAST_RAW}ms`;
export const SPEED_MEDIUM = `${SPEED_MEDIUM_RAW}ms`;

export const TIMING_FUNCTION = 'cubic-bezier(.4, 0, .2, 1)';

// -- Typography--

export const ROOT_FONT_SIZE = '16px'; // Only meant to be used in CSS, please use `DEFAULT_FONT_SIZE` instead

export const DEFAULT_FONT_FAMILY = 'Lato, Helvetica, Arial, sans-serif';
export const DEFAULT_FONT_SIZE = '1rem'; // 16px
export const DEFAULT_LINE_HEIGHT = '1.5'; // 24px

export const SMALL_FONT_SIZE = '.75rem'; // 12px
export const SMALL_LINE_HEIGHT = '1.33333'; // 16px

export const TINY_FONT_SIZE = '.625rem'; // 10px;
export const TINY_LINE_HEIGHT = '1.2'; // 12px

export const BIG_FONT_SIZE = '1.25rem'; // 20px

export const MIN_BODY_WIDTH = '300px';

// TODO: Clarify the styles as there's no much info in the styleguide
export const LINK = css`
  color: ${colors.text.blue};
  text-decoration: underline;
  transition: color ${SPEED_FAST};
`;

export const NAVIGATION_LINK = css`
  color: ${colors.text.secondary};
  text-decoration: none;

  &:hover {
    color: ${colors.text.disabled};
    cursor: pointer;
  }

  &:active {
    color: ${colors.text.secondary};
  }
`;

// -- Grid --

export const GRID_COLUMNS = 12;

export const GRID_HALF_GUTTER_WIDTH_RAW = SPACE3_RAW;
export const GRID_HALF_GUTTER_WIDTH = `${GRID_HALF_GUTTER_WIDTH_RAW}px`;

export const GRID_GUTTER_WIDTH_RAW = 2 * GRID_HALF_GUTTER_WIDTH_RAW;
export const GRID_GUTTER_WIDTH = `${GRID_GUTTER_WIDTH_RAW}px`;

export const GRID_LARGE_WIDTH = 1152;
export const GRID_MEDIUM_WIDTH = 960;
export const GRID_SMALL_WIDTH = 744;
export const GRID_EXTRA_SMALL_WIDTH = '100%';

// As the browser seemed not to be able to always retrieve a pixel perfect value, we help it a bit
export const GRID_LARGE_COLUMN_WIDTH = GRID_LARGE_WIDTH / GRID_COLUMNS;
export const GRID_MEDIUM_COLUMN_WIDTH = GRID_MEDIUM_WIDTH / GRID_COLUMNS;
export const GRID_SMALL_COLUMN_WIDTH = GRID_SMALL_WIDTH / GRID_COLUMNS;

// -- Layers --
// TODO: These layers are not complete, a general concept is missing for now

export const LAYER_BACKGROUND = css`
  z-index: -1;
`;

export const LAYER_ABOVE_CONTENT = css`
  z-index: 9;
`;

export const LAYER_OVERLAY = css`
  z-index: 9999;
`;

export const LAYER_GRID_OVERLAY = css`
  z-index: 9999999;
`;

// -- Other --

export const MENU_SHADOW = css`
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.3);
`;

export const BORDER_RADIUS = css`
  border-radius: 4px;
`;

export const CENTERED_CONTENT = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media (min-width: 900px) {
    max-width: ${GRID_MEDIUM_COLUMN_WIDTH * 10 - GRID_GUTTER_WIDTH_RAW}px;
  }

  @media (min-width: 1200px) {
    max-width: ${GRID_LARGE_COLUMN_WIDTH * 8 - GRID_GUTTER_WIDTH_RAW}px;
  }
`;
