import { styled } from '@mui/material/styles';

const Wrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

export const LegalTextSea = () => (
  <Wrapper>
    Laufzeit: Minimum 6 Monate, Maximum 120 Monate | Zinssätze: Minimum -0,40 %
    effektiver Jahreszins, Maximum 19,90 % effektiver Jahreszins
  </Wrapper>
);
