import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import { AccordionContainer as Accordion } from '../../Accordion/AccordionContainer';
import { Logo } from './Logo';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import { getSessionStorage } from 'lib/storage/storage';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';

const { colors } = finanzcheck;
const StyledDiv = styled('div')(({ theme }) => ({
  height: '60px',
  [theme.breakpoints.up('sm')]: {
    height: '70px',
  },
  [theme.breakpoints.up('md')]: {
    height: '80px',
  },
}));

const HeaderWrapper = styled('header')({
  borderBottom: `1px solid ${colors.neutral.grey100}`,
});

const TopLeftButtonWrapper = styled(StyledDiv)({
  display: 'flex',
  flex: '1 0 100%',
});

const LogoWrapper = styled(StyledDiv)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flext-start',
  },
}));

const TopRightButtonWrapper = styled(StyledDiv)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
});

const AccordionWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const StyledLogo = styled(Logo)`
  max-height: 28px; /* IE11 fix */
`;

interface HeaderProps {
  TopLeftButton?: React.ReactNode;
  TopRightButton?: React.ReactNode;
  AccordionContent?: React.ReactNode;
  Navigation?: React.ReactNode;
  isAccordionOpen?: boolean;
  fcLogoClickable?: boolean;
  isAntrag?: boolean;
}

/**
 * Header template
 * Renders per default empty and containing only the logo. Each element can be passed over using composition.
 *
 * @param {Object}  props
 * @param {Node}    [props.TopLeftButton=null]    - Top left button component
 * @param {Node}    [props.TopRightButton=null]   - Top right button component
 * @param {Node}    [props.AccordionContent=null] - Content component for the accordion
 * @param {Node}    [props.Navigation=null]       - Navigation component
 * @param {boolean} [props.isAccordionOpen=false] - Indicates if the accordion should be open
 * @param {String}  [props.backUrl='/']           - The url to browse to when clicking the logo
 */
export const HeaderTemplate: React.FC<HeaderProps> = ({
  TopLeftButton,
  TopRightButton,
  AccordionContent,
  Navigation,
  isAccordionOpen,
  fcLogoClickable,
  isAntrag,
}) => {
  // The backUrl has to be handled dynamically when used in conjunction with iMod
  const [backUrl, setBackUrl] = useState('/');
  useEffect(() => {
    if (!isAntrag) {
      return;
    }
    const url = getSessionStorage(STORAGE_CONSTANTS.backUrl) || '';
    setBackUrl(url);
  }, [setBackUrl, isAntrag]);

  return (
    <HeaderWrapper>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={3} display={{ md: 'none' }}>
            {TopLeftButton && (
              <TopLeftButtonWrapper>{TopLeftButton}</TopLeftButtonWrapper>
            )}
          </Grid>
          <Grid item xs={6}>
            <LogoWrapper>
              {fcLogoClickable ? (
                <a href={backUrl}>
                  <StyledLogo />
                </a>
              ) : (
                <StyledLogo />
              )}
            </LogoWrapper>
          </Grid>
          <Grid item xs={3} md={6}>
            <TopRightButtonWrapper>{TopRightButton}</TopRightButtonWrapper>
          </Grid>
        </Grid>
        {AccordionContent && (
          <AccordionWrapper>
            <Accordion isOpen={isAccordionOpen}>{AccordionContent}</Accordion>
          </AccordionWrapper>
        )}
        {Navigation}
      </Container>
    </HeaderWrapper>
  );
};
