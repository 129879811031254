import { memo, useState } from 'react';

import { CONFIG } from 'lib/constants/config';
import { getConfig } from 'lib/helpers/config';
import { TrackingEvents } from 'lib/tracking/events';
import { track } from 'lib/tracking/helper';

import { HeaderTemplate } from './HeaderTemplate';
import { CallForAdvice } from './CallForAdvice';
import { AccordionContent } from './AccordionContent';
import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Container for the header component, containing a state and callback to define if the customer service
 * collapsible should be displayed on mobile.
 *
 * @param {Object} props - Props to be passed over
 */

const getAccordionContent = (phone: string) => (
  <AccordionContent phone={phone} />
);

interface HeaderContainerProps {
  Navigation?: React.ReactNode;
  TopLeftButton?: React.ReactNode;
}

export const HeaderContainer: React.FC<HeaderContainerProps> = memo((props) => {
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const theme = useTheme();
  const matchesSmallUp = useMediaQuery(theme.breakpoints.up('sm'));

  const onCallForAdviceClick = (event: React.MouseEvent) => {
    if (matchesSmallUp) {
      // desktop only, mobile tracking is in AccordionContent.jsx
      track(TrackingEvents.PHONEBOX_BUTTON_ONINTERACT);
    } else {
      // mobile only
      setAccordionOpen(!isAccordionOpen);
      event.preventDefault();
    }
  };

  const phone = getConfig(CONFIG.PHONE) || '';
  return (
    <HeaderTemplate
      {...props}
      AccordionContent={getAccordionContent(phone)}
      TopRightButton={
        <CallForAdvice
          phone={phone}
          onCallForAdviceClick={onCallForAdviceClick}
        />
      }
      isAccordionOpen={isAccordionOpen}
    />
  );
});

HeaderContainer.displayName = 'HeaderContainer';
