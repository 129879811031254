import { logForDev } from '../logging/devLogger';

interface Input {
  ruleKey: string;
  variationKey: string;
}

// https://docs.developers.optimizely.com/optimizely-data/docs/event-api-getting-started
interface DecisionEvent {
  campaign_id: string;
  experiment_id: string;
  variation_id: string;
}

export function getDecisionDetails(
  parsedDatafile: any,
  input: Input,
): DecisionEvent {
  const df = parsedDatafile;
  const result: DecisionEvent = {
    campaign_id: '',
    experiment_id: '',
    variation_id: '',
  };

  if (!df || !df.experiments) {
    logForDev('Optimizely: invalid config');
  }

  const getExperiment = (e: any) => e.key === input.ruleKey;

  const experiment =
    df.experiments.find(getExperiment) ||
    df.groups
      .map((group: any) => group.experiments.find(getExperiment))
      .filter((experimentInGroup: any) => experimentInGroup)[0];

  if (!experiment) {
    logForDev('Optimizely: experiment does not exist');
  }
  result.experiment_id = experiment.id;
  result.campaign_id = experiment.layerId;

  if (!experiment.variations) {
    logForDev('Optimizely: invalid experiment object');
  }
  const variation = (experiment.variations as Array<any>).find(
    (v) => v.key === input.variationKey,
  );
  if (!variation) {
    logForDev('Optimizely: variation not assigned to experiment');
  }
  result.variation_id = variation.id;
  return result;
}
