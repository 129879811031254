import { css } from '@emotion/react';

import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import {
  SPACE8,
  SPEED_FAST,
  TIMING_FUNCTION,
} from 'lib/constants/layout';

const { colors } = finanzcheck;

export const buttonStyles = css`
  align-items: center;
  align-self: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${colors.text.blue};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent; /* Remove grey highlight */
  text-decoration: none;
  transition: color ${SPEED_FAST} ${TIMING_FUNCTION};
  user-select: none;
  vertical-align: middle;

  &::-moz-focus-inner {
    border-style: none; /* Remove Firefox dotted outline. */
  }

  &:hover,
  &:focus,
  &:active {
    color: ${colors.primary[400]};
  }
  width: ${SPACE8}
  // extra small is the smalles, that means for everybody
`;
