import { CONFIG } from '../constants/config';

type staticConfig = {
  [CONFIG.PHONE]?: string;
  [CONFIG.BUILD_ID]?: string;
};

let config = {} as staticConfig;

const initConfig = (staticConfig: Record<string, unknown>) => {
  config = { ...staticConfig };
};

const getConfig = (key: string) => {
  switch (key) {
    case CONFIG.PHONE:
      return config[CONFIG.PHONE];
    case CONFIG.BUILD_ID:
      return config[CONFIG.BUILD_ID];
    default:
      return '';
  }
};

export { getConfig, initConfig };
