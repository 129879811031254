import { FooterMinimal } from './FooterMinimal/FooterMinimal';
import { FooterFull } from './FooterFull/FooterFull';

interface FooterProps {
  footerStyle?: string;
  showLegalText?: boolean;
  mt?: number;
  datenschutzUrl?: string;
}

export const DEFAULT_FOOTER_MARGIN_TOP = 64;

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const {
    footerStyle,
    showLegalText = false,
    mt = DEFAULT_FOOTER_MARGIN_TOP,
    datenschutzUrl,
  } = props;

  if (footerStyle === 'minimal') {
    return (
      <FooterMinimal
        mt={mt}
        showLegalText={showLegalText}
        datenschutzUrl={datenschutzUrl}
      />
    );
  }

  return (
    <FooterFull
      mt={mt}
      showLegalText={showLegalText}
      datenschutzUrl={datenschutzUrl}
    />
  );
};
