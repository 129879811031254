import { Theme, styled } from '@mui/material/styles';

import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import {
  DEFAULT_FONT_SIZE,
  LAYER_GRID_OVERLAY,
  NAVIGATION_LINK,
  SPACE,
  SPACE2,
  SPACE4,
  SPACE5,
  SPEED_FAST,
  TIMING_FUNCTION,
} from '../../../lib/constants/layout';
import Grid from '@mui/material/Grid';

import { TrackingEvents } from 'lib/tracking/events';
import { track } from '../../../lib/tracking/helper';

import menuData from './menuData.json';
const { colors } = finanzcheck;

const clientAreaUrl = process.env.WEBSITE_CLIENTAREA_URL;

const wrapper = (theme: Theme) => ({
  fontSize: '14px',
  paddingBottom: SPACE2,
  [theme.breakpoints.up('md')]: {
    fontSize: DEFAULT_FONT_SIZE,
  },
});

const NavigationWrapper = styled('nav')(({ theme }) => ({
  ...wrapper(theme),
  display: 'flex',
}));

const MenuItem = styled('div')`
  margin-right: ${SPACE4};
  position: relative;

  & a {
    ${NAVIGATION_LINK};
    white-space: nowrap;
  }

  & > div + a {
    &::after {
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      border-top: 0.25rem solid;
      content: '';
      display: inline-block;
      height: 0;
      margin-left: 0.25rem;
      vertical-align: middle;
      width: 0;
    }
  }

  & > div {
    background-color: ${colors.background.default};
    border: 1px solid ${colors.text.disabled};
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.175);
    min-width: 160px;
    opacity: 0;
    padding: ${SPACE} 0;
    position: absolute;
    top: 100%;
    /* stylelint-disable-next-line declaration-colon-newline-after */
    transition:
      opacity ${SPEED_FAST} ${TIMING_FUNCTION},
      visibility 0s ${SPEED_FAST};
    visibility: hidden;
    ${LAYER_GRID_OVERLAY};
  }

  & > div > a {
    display: block;
    padding: ${SPACE} ${SPACE5};
  }

  &:hover > div {
    opacity: 1;
    transition:
      opacity ${SPEED_FAST} ${TIMING_FUNCTION},
      visibility 0s;
    visibility: visible;
  }
`;

const ClientArea = styled('a')(({ theme }) => ({
  ...wrapper(theme),
  color: colors.text.blue,
  textDecoration: 'none',
  display: 'block',
  float: 'right',
  width: '100%',
  textAlign: 'right',

  '&:hover, &:active': {
    color: colors.text.disabled,
    cursor: 'pointer',
  },
}));

/**
 * Desktop navigation
 */
export const Navigation = () => {
  return (
    <Grid container>
      <Grid item display={{ xs: 'none', md: 'block' }} sm={10}>
        <NavigationWrapper role="navigation">
          {menuData.map((item) => (
            <MenuItem key={item.label}>
              {item.subMenu && (
                <div>
                  {item.subMenu.map((subItem) => (
                    <a href={subItem.url} key={subItem.label}>
                      {subItem.label}
                    </a>
                  ))}
                </div>
              )}
              <a href={item.url}>{item.label}</a>
            </MenuItem>
          ))}
        </NavigationWrapper>
      </Grid>
      <Grid item sm={2} display={{ xs: 'none', md: 'block' }}>
        <ClientArea
          href={clientAreaUrl}
          onClick={() => {
            track(TrackingEvents.LOGIN_LINK_ONINTERACT);
          }}
        >
          Kundenbereich
        </ClientArea>
      </Grid>
    </Grid>
  );
};
