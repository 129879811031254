import { styled } from '@mui/material/styles';
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaXing,
  FaLinkedinIn,
} from 'react-icons/fa';

import { SPEED_FAST, TIMING_FUNCTION } from 'lib/constants/layout';
import {
  FACEBOOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  XING_URL,
  LINKEDIN_URL,
} from 'lib/constants/social';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
  },
}));

const Button = styled('a')(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '50%',
  color: theme.palette.primary.main,
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  transition: `border-color ${SPEED_FAST} ${TIMING_FUNCTION}`,
  width: 40,
  ['&:hover']: {
    borderColor: theme.palette.primary.main,
  },
}));

/**
 * Social links
 */
export const Social = () => {
  return (
    <Wrapper>
      <Button
        href={FACEBOOK_URL}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
        title="Besuchen Sie uns auf Facebook"
      >
        <FaFacebookF />
      </Button>
      <Button
        href={TWITTER_URL}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
        title="Besuchen Sie uns auf Twitter"
      >
        <FaTwitter />
      </Button>
      <Button
        href={YOUTUBE_URL}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
        title="Besuchen Sie uns auf YouTube"
      >
        <FaYoutube />
      </Button>
      <Button
        href={XING_URL}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
        title="Besuchen Sie uns auf Xing"
      >
        <FaXing />
      </Button>
      <Button
        href={LINKEDIN_URL}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
        title="Besuchen Sie uns auf LinkedIn"
      >
        <FaLinkedinIn />
      </Button>
    </Wrapper>
  );
};
