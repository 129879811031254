export const STORAGE_CONSTANTS = {
  actionVoucherCode: 'FC_VOUCHER_CODE',
  actionVoucherCampaign: 'FC_VOUCHER_CAMPAIGN',
  iModCurrentNavigation: 'IMOD_CURRENT_NAVIGATION',
  iModCurrentTraversalData: 'IMOD_CURRENT_TRAVERSAL_DATA',
  browserId: 'BROWSER_ID',
  refId: 'REF_ID',
  backUrl: 'BACK_URL',
  iframeUrl: 'IFRAME_URL',
};
