export enum CONFIG {
  BUILD_ID = 'buildId',
  CONFIG_ID = 'configId',
  MINIMIAL_HEADER_FOOTER = 'minimizeHeaderAndFooter',
  PAGE_CONFIG = 'pageConfig',
  PHONE = 'phone',
  // NOTE= known keys from pageConfig
  AMOUNT = 'userAmount',
  PURPOSE = 'userPurpose',
  TERM = 'userTerm',
  REGISTRATION_ROUTE = 'registrationRoute',
}

export const DEFAULT_CONFIG = {
  phone: '0800 433 88 77',
  buildId: '/',
};
