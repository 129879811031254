import { Fragment, useState } from 'react';

import { SPEED_MEDIUM_RAW } from '../../../lib/constants/layout';
import { Burger } from './Burger';
import { Close } from './Close';
import { MobileOverlay } from './MobileOverlay';

/**
 * Burger menu container
 * Renders the menu button and the menu overlay if it should be displayed, `null` otherwise.
 * It's responsible for the toggling from one state to the other and it  also contains some
 * logic to allow animating the transition.
 */
export const BurgerMenuContainer = () => {
  const [isInDOM, setInDOM] = useState(false);
  const [isVisible, setVisible] = useState(false);
  return (
    <Fragment>
      <Burger
        key="burger"
        onClick={() => {
          if (!isInDOM && !isVisible) {
            setInDOM(true); // First we bring the HTML in the DOM
            setTimeout(() => {
              setVisible(true);
            }, 0); // Then we can animate how it is shown
            document.body.style.overflow = 'hidden'; // Disallow scrolling on the body
          }
        }}
      />
      {isInDOM && (
        <MobileOverlay
          key="overlay"
          show={isVisible}
          Close={
            <Close
              onClick={() => {
                if (isInDOM && isVisible) {
                  setVisible(false); // Animate the way we hide it
                  setTimeout(() => {
                    setInDOM(false);
                  }, SPEED_MEDIUM_RAW); // Once the animation is over, we remove it from DOM
                  document.body.style.overflow = 'auto'; // Allow again scrolling
                }
              }}
            />
          }
        />
      )}
    </Fragment>
  );
};
