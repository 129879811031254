import { memo } from 'react';
import { Button as MUIButton } from '@mui/material';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import { KUZA_INPUT_FIELDS_MAX_WIDTH } from '../constants';
import { Wave } from '../Wave/Wave';
import { styled } from '@mui/material/styles';

const { colors } = finanzcheck;

export const StyledButton = styled(MUIButton, {
  shouldForwardProp: (props) => props !== 'labelColour',
})<{ labelColour: string }>(({ labelColour }) => ({
  '&.MuiButton-label': {
    color: labelColour,
  },
  maxWidth: KUZA_INPUT_FIELDS_MAX_WIDTH,
}));

const getColourCode = (colour: string) => {
  switch (colour) {
    case 'green':
      return colors.success.dark;
    case 'blue':
      return colors.primary.main;
    case 'orange':
      return colors.secondary.main;
    case 'black':
      return colors.text.primary;
    case 'white':
    default:
      return colors.background.default;
  }
};

interface ButtonProps {
  children?: React.ReactNode;
  flat?: boolean;
  type?: 'button' | 'submit' | 'reset';
  color?: string;
  size?: 'small' | 'large' | 'medium';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  secondary?: boolean;
  visible?: boolean;
  href?: string;
  disabled?: boolean;
  target?: string;
  text?: string;
  labelColour?: string;
  loading?: boolean;
  LinkComponent?: any;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = memo(
  ({
    children,
    flat = false,
    type = 'button',
    loading = false,
    color = 'primary',
    size = 'large',
    href,
    startIcon,
    endIcon,
    fullWidth,
    className,
    secondary,
    visible = true,
    disabled = false,
    target,
    text,
    labelColour,
    ...restProps
  }) => {
    const hrefProps = {
      href,
      target,
      role: 'button',
      'aria-disabled': disabled,
    };

    const childs = text ? text : children;
    const newColor = secondary
      ? 'secondary'
      : color === 'secondary'
      ? 'warning'
      : 'primary';
    const newLabelColour = labelColour
      ? getColourCode(labelColour)
      : colors.background.default;

    return (
      <StyledButton
        variant={flat ? 'text' : 'contained'}
        type={href ? undefined : type}
        color={newColor}
        size={size}
        fullWidth={fullWidth}
        startIcon={loading ? null : startIcon}
        endIcon={loading ? null : endIcon}
        className={className}
        disabled={disabled || !visible}
        labelColour={newLabelColour}
        {...restProps}
        {...(href ? hrefProps : {})}
      >
        <span>{loading ? <Wave /> : childs}</span>
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';
