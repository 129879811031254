import { Config } from '@optimizely/optimizely-sdk';
import fetcher from 'lib/fetcher';

export const getDatafile = async () => {
  try {
    return await fetcher<Pick<Config, 'datafile'>>(
      `https://cdn.optimizely.com/datafiles/${process.env.OPTIMIZELY_SDK_KEY}.json`,
    );
  } catch {
    return null;
  }
}
