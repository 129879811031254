import { useContext, createContext, useState, PropsWithChildren } from 'react';
import { Testimonial, AverageScore } from 'types/ekomi';
import testimonials from 'public/api/ekomi/testimonials.json';
import averageScore from 'public/api/ekomi/averageScore.json';
import DEFAULT_EKOMI from 'lib/ekomi/ekomiDefaults.json';

interface IEkomi {
  averageScore: AverageScore;
  testimonials: Testimonial[];
}

export type EkomiContextType = {
  ekomi: IEkomi;
  setEkomi: (ekomi: IEkomi) => void;
};

/**
 * Create the context itself that is used within the
 * consumer and provider
 */
export const EkomiContext = createContext<EkomiContextType | null>({
  ekomi: DEFAULT_EKOMI as IEkomi,
  setEkomi: () => {},
});

export const useEkomi = () => {
  const context = useContext(EkomiContext);
  if (!context) {
    throw new Error('useEkomi must be used inside the EkomiProvider');
  }

  return context;
};

/**
 * The provider acts a wrapper to provide the context
 * to its children
 */
export const EkomiProvider = ({ children }: PropsWithChildren<{}>) => {
  const ekomiFromServerApi = {
    averageScore,
    testimonials,
  } as IEkomi;

  const [ekomi, setEkomi] =
    useState<EkomiContextType['ekomi']>(ekomiFromServerApi);

  return (
    <EkomiContext.Provider value={{ ekomi, setEkomi }}>
      {children}
    </EkomiContext.Provider>
  );
};
