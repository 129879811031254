import { styled } from '@mui/material/styles';

import { CITY, STREET, ZIP } from '../../../lib/constants/address';
import { SPACE2 } from '../../../lib/constants/layout';

import { Container, Typography, Grid, Box } from '@mui/material';

import {
  ContentWrapper,
  Copyright,
  FooterWrapper,
  Spacer,
  StyledGrid,
  StyledLock,
} from './FooterTemplate.style';
import { TealFooterUnified } from '@finanzcheck/teal-components';

const LegalWrapper = styled('div')`
  margin-top: ${SPACE2};
`;
const footerLinks = {
  agb: '/uber-uns/agb/',
  impressum: '/uber-uns/impressum/',
  datenschutz: '/uber-uns/datenschutz/',
};
interface FooterTemplateProps {
  Navigation?: React.ReactNode;
  RightContent?: React.ReactNode;
  LegalText?: React.ReactNode;
  mt?: number;
  datenschutzUrl?: string;
}

/**
 * Footer template
 * Renders per default empty and containing only the copyright text and address. Each element can be passed over using composition.
 *
 * @param {Object} props
 * @param {Node}   [props.Navigation=null]   - Footer navigation
 * @param {Node}   [props.RightContent=null] - Bottom right content
 */
export const FooterTemplate: React.FC<FooterTemplateProps> = ({
  LegalText,
  Navigation,
  RightContent,
  mt,
  datenschutzUrl,
}) => {
  if (!LegalText) {
    return (
      <>
        {Navigation && (
          <FooterWrapper style={{ marginTop: mt }}>
            <Container>{Navigation}</Container>
          </FooterWrapper>
        )}
        <Box
          mt={(theme) => theme.spacing(3)}
          borderTop={(theme) => `1px solid ${theme.palette.grey[300]}`}
        >
          <TealFooterUnified
            origin="finanzcheck"
            oneTrustHandler={() => window?.OneTrust?.ToggleInfoDisplay()}
            footerLinks={{
              ...footerLinks,
              ...(datenschutzUrl && { datenschutz: datenschutzUrl }),
            }}
          />
        </Box>
      </>
    );
  }
  return (
    <FooterWrapper style={{ marginTop: mt }}>
      <Container maxWidth="lg">
        {Navigation}
        <StyledGrid container>
          <Grid item xs={12}>
            <ContentWrapper>
              <Copyright>
                <StyledLock size={12} />
                <Spacer>
                  <Typography variant="body2" component="span">
                    © {new Date().getFullYear()} FINANZCHECK.de
                  </Typography>
                </Spacer>
                <Spacer>
                  <Typography variant="body2" component="span">
                    {STREET} - {ZIP} {CITY}{' '}
                  </Typography>
                </Spacer>
              </Copyright>
              {RightContent}
            </ContentWrapper>
          </Grid>
          {LegalText && (
            <Grid item>
              <LegalWrapper>{LegalText}</LegalWrapper>
            </Grid>
          )}
        </StyledGrid>
      </Container>
    </FooterWrapper>
  );
};

export { Spacer };
