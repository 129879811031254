import {
  AMOUNT_MAX,
  AMOUNT_MIN,
  MAX_AMOUNT_ERROR,
  MIN_AMOUNT_ERROR,
  PURPOSES,
  TERMS,
} from 'lib/constants/offerFilter';

export const addDotInAmount = (amount: number): string => {
  return amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
};

export const sendAmountValidationError = (value: number | string): string => {
  const amount = removeDotInAmount(value);
  const integerAmount = parseInt(String(amount), 10);
  let amountError = '';
  if (integerAmount < AMOUNT_MIN) {
    amountError = MIN_AMOUNT_ERROR;
  }
  if (integerAmount > AMOUNT_MAX) {
    amountError = MAX_AMOUNT_ERROR;
  }
  return amountError;
};

export const removeDotInAmount = (amount: string | number): number | string => {
  if (amount && typeof amount === 'string') {
    const number = Number(amount.split('.').join(''));
    if (!isNaN(number)) {
      return number;
    }
  }
  return amount;
};

export const TERMS_OPTIONS = Object.keys(TERMS).reduce((acc, key) => {
  const label = TERMS[key as unknown as keyof typeof TERMS];
  acc.push({
    value: key as unknown as number,
    label,
  });
  return acc;
}, [] as { value: number; label: string }[]);

export const PURPOSES_OPTIONS = Object.keys(PURPOSES).reduce((acc, key) => {
  const label = PURPOSES[key as keyof typeof PURPOSES];
  acc.push({
    value: key as string,
    label,
  });
  return acc;
}, [] as { value: string; label: string }[]);

export const DEFAULT_SELECTION_VALUES = Object.freeze({
  amount: 20000,
  term: 84,
  purpose: 'other',
});
