export const isLive = () => {
  return process.env.RELEASE_STAGE === 'live';
};

export const isStage = () => {
  return process.env.RELEASE_STAGE === 'stage';
};

export const isShared = () => {
  return process.env.RELEASE_STAGE === 'shared';
};
