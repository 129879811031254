import { styled } from '@mui/material/styles';

import { buttonStyles } from './styles';

/**
 * Burger menu icon
 *
 * @param {Object} props
 * @param {string} props.className - Styles passed to the icon
 * @param {Function} props.onClick - On click callback
 */
interface BurgerProps {
  className?: string;
  onClick: () => void;
}

const BurgerSVG: React.FC<BurgerProps> = ({ className, onClick }) => (
  <button
    type="button"
    className={className}
    data-testid={'burgermenu_button'}
    onClick={onClick}
  >
    <svg
      className={className}
      viewBox="0 0 24 24"
      version="1.1"
      role="img"
      aria-labelledby="menu"
    >
      <title>Menu</title>
      <defs>
        <path
          id="burger-path"
          d="M21,17.5 L21,16.5 C21,16.2238576 20.7761424,16 20.5,16 L3.5,16 C3.22385763,16 3,16.2238576 3,16.5 L3,17.5 C3,17.7761424 3.22385763,18 3.5,18 L20.5,18 C20.7761424,18 21,17.7761424 21,17.5 Z M21,12.5 L21,11.5 C21,11.2238576 20.7761424,11 20.5,11 L3.5,11 C3.22385763,11 3,11.2238576 3,11.5 L3,12.5 C3,12.7761424 3.22385763,13 3.5,13 L20.5,13 C20.7761424,13 21,12.7761424 21,12.5 Z M3.5,8 L20.5,8 C20.7761424,8 21,7.77614237 21,7.5 L21,6.5 C21,6.22385763 20.7761424,6 20.5,6 L3.5,6 C3.22385763,6 3,6.22385763 3,6.5 L3,7.5 C3,7.77614237 3.22385763,8 3.5,8 Z"
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <mask id="burger-mask" fill="transparent">
          <use xlinkHref="#burger-path" />
        </mask>
        <use fill="currentColor" xlinkHref="#burger-path" />
        <g mask="url(#burger-mask)" fill="#currentColor">
          <rect x="0" y="0" width="24" height="24" />
        </g>
      </g>
    </svg>
  </button>
);

/**
 * Burger menu button
 */
export const Burger = styled(BurgerSVG)`
  ${buttonStyles};
`;
