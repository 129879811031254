import { Global, css } from '@emotion/react';

import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  LINK,
  MIN_BODY_WIDTH,
  ROOT_FONT_SIZE,
} from 'lib/constants/layout';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';

const { colors } = finanzcheck;
/**
 * React component injecting global layout styles.
 */
export function LayoutCSS() {
  return (
    <Global
      styles={css`
        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        html {
          box-sizing: border-box;
          font-size: ${ROOT_FONT_SIZE};
        }

        body {
          background-color: #fff;
          color: ${colors.text.primary};
          font-family: ${DEFAULT_FONT_FAMILY};
          font-size: ${DEFAULT_FONT_SIZE};
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-weight: normal;
          line-height: ${DEFAULT_LINE_HEIGHT};
          min-width: ${MIN_BODY_WIDTH};
          text-rendering: optimizeLegibility;
          overflow-x: hidden;
        }

        a {
          ${LINK};
        }

        img {
          height: auto;
          max-width: 100%;
        }

        /* TODO: [LEGO] Overwritting styles in the case legacy CSS is being used on Lego pages. Can be removed after the migration is done */
        svg {
          height: auto;
        }
      `}
    />
  );
}
