import { styled } from '@mui/material/styles';

import {
  FooterLink,
  Spacer,
  LinkButton,
} from '../FooterTemplate/FooterTemplate.style';

const Legal = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 100%',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
    flex: '1 0 auto',
    textAlign: 'right',
  },
}));

const MinimalSpacer = styled(Spacer)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    ['&:not(:last-child)::after']: {
      content: '""',
      margin: '0 3px',
    },
  },
  [theme.breakpoints.up('md')]: {
    ['&:not(:last-child)::after']: {
      content: '""',
      margin: `0 ${theme.spacing(2)}`,
    },
  },
}));

interface MinimalLinksProps {
  target: '_blank' | '_self';
  datenschutzUrl?: string;
}

/**
 * Mininal set of links to be present on the footer.
 * They will be placed on bottom right corner of the page.
 *
 * @param {Object}  props
 * @param {'_blank'|'_self'} target - The anchor target to use for the links
 */
export const MinimalLinks: React.FC<MinimalLinksProps> = ({
  target,
  datenschutzUrl,
}) => {
  const privacyNoteLink = datenschutzUrl || '/ubers-uns/datenschutz/';
  return (
    <Legal>
      <MinimalSpacer>
        <FooterLink
          href="/uber-uns/impressum/"
          target={target}
          rel="noopener noreferrer"
        >
          Impressum
        </FooterLink>
      </MinimalSpacer>
      <MinimalSpacer>
        <FooterLink
          href={privacyNoteLink}
          target={target}
          rel="noopener noreferrer"
        >
          Datenschutz
        </FooterLink>
      </MinimalSpacer>

      <MinimalSpacer>
        <LinkButton onClick={() => window.OneTrust?.ToggleInfoDisplay()}>
          Cookie Einstellungen
        </LinkButton>
      </MinimalSpacer>
    </Legal>
  );
};
