import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import getUserId from './getUserId';
import getOptimizelyAttributes from './getAttributes';
import { getDatafile } from './getDatafile';
import { logForDev } from 'lib/logging/devLogger';
// import Bugsnag from '../bugsnag';
import cookie from 'cookie';

const VARIATION_ASSIGNMENT_COOKIE = 'opuva';

export default function withOptimizelyProps<T extends { [key: string]: any }>(
  getServerSideProps: GetServerSideProps<T>,
) {
  return async (ctx: GetServerSidePropsContext) => {
    let userAttributes: any = {};
    try {
      userAttributes = getOptimizelyAttributes(
        ctx.req.headers['user-agent'] || '',
        ctx.req.url || '/',
      );
    } catch (e: any) {
      // Bugsnag.notify(e);
    }
    const user = {
      id: getUserId(ctx),
      attributes: userAttributes,
    };
    var start = new Date().getTime();
    const datafile = await getDatafile();
    var end = new Date().getTime();
    logForDev('Time', end - start);
    const data = (await getServerSideProps(ctx)) || {};

    const cookies = cookie.parse(ctx.req.headers.cookie ?? '');
    const serializedVariationAssignments =
      cookies[VARIATION_ASSIGNMENT_COOKIE] || '';

    return {
      ...data,
      props: {
        ...('props' in data && data.props),
        user,
        datafile,
        serializedVariationAssignments,
      },
    };
  };
}
