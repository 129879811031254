import {
  getHashParamsFromUrl,
  getQueryParamsFromUrl,
} from 'lib/helpers/urlUtils';

const isValidPosInteger = (
  value: string | string[] | undefined | number | null,
) => {
  const parsedValue = parseToInt(value);
  return parsedValue >= 0;
};

const parseToInt = (value: string | string[] | undefined | number | null) => {
  const stringValue = String(value);
  const parsedValue = parseInt(stringValue);

  return parsedValue;
};

const getLoanSelectionFromUrlParams = (url: string) => {
  try {
    const {
      amount: queryAmount,
      term: queryTerm,
      purpose: queryPurpose,
    } = getQueryParamsFromUrl(url);

    const {
      amount: hashAmount,
      term: hashTerm,
      purpose: hashPurpose,
    } = getHashParamsFromUrl(url);

    // We need to distguish betweeen value 0 and value undefined or null
    let amount = null;
    if (parseToInt(hashAmount) || queryAmount === '0')
      amount = parseToInt(hashAmount);
    if (parseToInt(queryAmount) || queryAmount === '0')
      amount = parseToInt(queryAmount);

    const urlParams = {
      amount,
      purpose: hashPurpose || queryPurpose || null,
      term: parseToInt(hashTerm) || parseToInt(queryTerm) || null,
    };

    return urlParams;
  } catch (_error) {
    return null;
  }
};

export { getLoanSelectionFromUrlParams, parseToInt, isValidPosInteger };
