import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { SPACE12 } from 'lib/constants/layout';
import { Button } from '../BaseElements/Button/Button';

const ButtonWithMargin = styled(Button)`
  margin-bottom: ${SPACE12};
`;

interface TemplateErrorProps {
  statusCode?: number;
}

export const TemplateError: React.FC<TemplateErrorProps> = ({ statusCode }) => {
  // TODO: After full migration we should use the next link as LinkComponent on the button
  // instead of using JS to set a new location

  let title;

  switch (statusCode) {
    case 404:
      title = 'Ups! Wir können Ihre gewünschte Seite nicht finden.';
      break;

    case 500:
      title = 'Ups! Da stimmt etwas nicht.';
      break;

    default:
      break;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h1" color="text.blue" mt={2} pt={4}>
        {title}
      </Typography>

      <Typography variant="body1">Fehlercode: {statusCode}</Typography>

      <Typography variant="body1" mb={2}>
        Starten Sie einen kostenlosen und unverbindlichen Kreditvergleich um von
        günstigen Sonderzinssätzen zu profitieren.
      </Typography>

      <ButtonWithMargin onClick={() => (global.location.href = '/')}>
        Jetzt Kredite vergleichen
      </ButtonWithMargin>
    </Container>
  );
};
