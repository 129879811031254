/* eslint-disable import/no-default-export */

import Head from 'next/head';
import { NextPage, NextPageContext } from 'next';

import { TemplateError } from 'components/TemplateError/TemplateError';
import { Footer } from 'components/Footer/Footer';
import { HeaderFull } from 'components/Header/HeaderFull/HeaderFull';
import { Layout } from 'components/Layout/Layout';

interface ErrorProps {
  statusCode: number;
}

const ErrorPage: NextPage<ErrorProps> = ({ statusCode }) => {
  let title;

  switch (statusCode) {
    case 500:
      title = '500 Server error';
      break;

    default:
      title = 'Error';
      break;
  }

  const FooterFull = () => <Footer footerStyle="full" />;

  return (
    <>
      <Head>
        <title key="title">{title}</title>
      </Head>
      <Layout Header={HeaderFull} Footer={FooterFull}>
        <TemplateError statusCode={statusCode} />
      </Layout>
    </>
  );
};

ErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode } as ErrorProps;
};

export default ErrorPage;
