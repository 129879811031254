import { styled } from '@mui/material/styles';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled('div', {
  shouldForwardProp: (props) => props !== 'backgroundColor',
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  flexGrow: 1,
}));

/**
 * Scaffolding for the whole page.
 * The content grows if needed to keep the footer at the bottom of the page.
 */

interface LayoutProps {
  Header?: React.FC<{ isAntrag: boolean; fcLogoClickable: boolean }>;
  isAntrag?: boolean;
  isLogoClickable?: boolean;
  Footer?: React.FC;
  backgroundColor?: string;
  children?: React.ReactNode;
}
export const Layout: React.FC<LayoutProps> = ({
  Header,
  Footer,
  isLogoClickable = true,
  backgroundColor = '',
  isAntrag = false,
  children,
}) => {
  // TODO: backgroundColor is just a workaround for the moment - better to get a more generic possibility
  return (
    <Wrapper>
      {Header && (
        <Header isAntrag={isAntrag} fcLogoClickable={isLogoClickable} />
      )}
      <Content backgroundColor={backgroundColor}>{children}</Content>
      {Footer && <Footer />}
    </Wrapper>
  );
};
