import { NormalizeCSS } from './css/NormalizeCSS';
import { LayoutCSS } from './css/LayoutCSS';

/**
 * Injects global styles.
 */
export const GlobalStyles: React.FC = () => {
  return (
    <>
      <NormalizeCSS />
      <LayoutCSS />
    </>
  );
};
