import * as React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { websiteGlobal } from '../../lib/platform';
import {
  isSessionStorageAccessible,
  isLocalStorageAccessible,
} from '../../lib/storage/isBrowserStorageAccessible';

const apiKey =
  process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || 'cb279bed18735df5bc4e2a2f8343e58c';
const appVersion = process.env.RELEASE_TAG || 'RELEASE_TAG';
const releaseStage = process.env.STAGE_ENV || 'development';

const userHasOptedOut = () =>
  document.cookie.indexOf('_ga_opt_out=true') !== -1;

const isStorageAvailable = () => ({
  sessionStorage: isSessionStorageAccessible,
  localStorage: isLocalStorageAccessible,
});

Bugsnag.start({
  apiKey: `${apiKey}`,
  collectUserIp: false,
  plugins: [new BugsnagPluginReact(React)],
  // TODO: releaseTag is undefined right now implementation hint: git describe --abbrev=0 --tags
  appVersion,
  releaseStage,
  enabledReleaseStages: ['shared', 'stage', 'live'],
  onError(event) {
    // See the bugsnag API docs what the event is comprised of:
    // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#the-event-object

    // suppress tracking of some common expected errors
    // if (event.originalError instanceof TypeError) {
    // TypeErrors will be thrown when various browsers hit the abort button during fetch.
    // E.g. mobile safari will throw TypeError: cancelled, which we see a lot
    // also network lost error will appear as TypeErrors
    //  return false;
    //}

    // These are peculiar errors we have in huge quantity that seem to
    // be originating alls from chrome, windows 10, with us locale.
    // See WS-53
    if (event.originalError.errorClass === 'InvalidError') {
      return false;
    }

    // NOTE: wanted keys from window.navigator.connection are readable only so for in loop is needed
    const connection: { [key: string]: unknown } = {};
    for (const key in websiteGlobal.navigator?.connection) {
      // NOTE: not using addEventListener etc.
      if (!key.includes('Event')) {
        connection[key] = websiteGlobal.navigator.connection[key];
      }
    }

    // NOTE: all tracking gets information about accessibility
    event.addMetadata('meta', {
      ...isStorageAvailable(),
      ...connection,
    });

    return !userHasOptedOut(); // abort if opted out
  },
});

export const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React);

export default Bugsnag;
