import { memo } from 'react';
import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')({
  '@keyframes wave': {
    '0%, 60%, 100%': {
      transform: 'initial',
    },
    '30%': {
      transform: 'translateY(-0.5rem)',
    },
  },
  animation: 'wave 0.9s linear infinite',
  background: 'rgba(0, 0, 0, 0.87)',
  borderRadius: '50%',
  display: 'inline-block',
  height: '0.5rem',
  marginRight: '3px',
  width: '0.5rem',
  '&:nth-of-type(2)': {
    animationDelay: '-0.6s',
  },
  '&:nth-of-type(3)': {
    animationDelay: '-0.4s',
  },
});

interface WaveProps {
  numberOfCircles?: number;
}

export const Wave: React.FC<WaveProps> = memo(({ numberOfCircles }) => {
  return (
    <>
      {[...Array(numberOfCircles).keys()].map((number) => (
        <StyledSpan key={number} />
      ))}
    </>
  );
});

Wave.displayName = 'Wave';
