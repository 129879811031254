import { useContext, createContext, useState, PropsWithChildren } from 'react';
import {
  getDefaultPageConfig,
  IPageConfig,
} from 'lib/pageConfig/pageConfigHelper';

export type PageConfigContextType = {
  pageConfig: IPageConfig;
  setPageConfig: (pageConfig: IPageConfig) => void;
};

export const PageConfigContext = createContext<PageConfigContextType | null>(
  null,
);

export const usePageConfig = () => {
  const context = useContext(PageConfigContext);
  if (!context) {
    throw new Error('usePageConfig must be used inside the PageConfigProvider');
  }

  return context;
};

export const PageConfigProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageConfig, setPageConfig] = useState<
    PageConfigContextType['pageConfig']
  >(getDefaultPageConfig());

  return (
    <PageConfigContext.Provider value={{ pageConfig, setPageConfig }}>
      {children}
    </PageConfigContext.Provider>
  );
};
