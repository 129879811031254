import { websiteGlobal } from '../platform';

const TEST_ITEM = 'testItem';
export const isSessionStorageAccessible = (() => {
  try {
    if (websiteGlobal.sessionStorage) {
      websiteGlobal.sessionStorage.setItem(TEST_ITEM, TEST_ITEM);
      websiteGlobal.sessionStorage.getItem(TEST_ITEM);
      websiteGlobal.sessionStorage.removeItem(TEST_ITEM);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
})();

export const isLocalStorageAccessible = (() => {
  try {
    if (websiteGlobal.localStorage) {
      websiteGlobal.localStorage.setItem(TEST_ITEM, TEST_ITEM);
      websiteGlobal.localStorage.getItem(TEST_ITEM);
      websiteGlobal.localStorage.removeItem(TEST_ITEM);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
})();
