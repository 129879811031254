import { createContext, PropsWithChildren, useContext, useState } from 'react';

export interface IBankLegalAddress {
  name: string;
  city: string;
  country: string;
  secondLine: string;
  street: string;
  zipCode: string;
}
export interface IBestOffer {
  bankName: string | undefined;
  bankLegalAddress: IBankLegalAddress;
  condition: {
    effectiveInterestRate: number;
    effectiveInterestRateMax: number;
    effectiveInterestRateTwoThird: number;
    fee: number;
    nominalInterestRate: number;
    nominalInterestRateMax: number;
    nominalInterestRateTwoThird: number;
    specialInterest: boolean;
  };
  monthlyRate: number;
}

export type BestOfferContextType = {
  bestOffer: IBestOffer | null;
  setBestOffer: (offer: IBestOffer | null) => void;
};

/**
 * Create the context itself that is used within the
 * consumer and provider
 */
export const BestOfferContext = createContext<BestOfferContextType>({
  bestOffer: null,
  setBestOffer: () => {},
});

export const useBestOffer = () => {
  const context = useContext(BestOfferContext);

  if (!context) {
    throw new Error('useBestOffer must be used inside the BestOfferProvider');
  }

  return context;
};

/**
 * The provider acts a wrapper to provide the context
 * to its children
 */
export const BestOfferProvider = ({ children }: PropsWithChildren<{}>) => {
  const [bestOffer, setBestOffer] = useState<IBestOffer | null>(null);

  return (
    <BestOfferContext.Provider value={{ bestOffer, setBestOffer }}>
      {children}
    </BestOfferContext.Provider>
  );
};
