import { styled } from '@mui/material/styles';

import { buttonStyles } from './styles';

/**
 * Close menu icon
 *
 * @param {Object} props
 * @param {string} props.className - Styles passed to the icon
 * @param {Function} props.onClick - On click callback
 */
interface CloseSVGProps {
  className?: string;
  onClick: () => void;
}
const CloseSVG: React.FC<CloseSVGProps> = ({ className, onClick }) => (
  <button
    type="button"
    className={className}
    data-testid={'burgermenu_close_button'}
    onClick={onClick}
  >
    <svg
      className={className}
      viewBox="0 0 24 24"
      role="img"
      aria-labelledby="menu"
    >
      <title>Schließen</title>
      <defs>
        <mask
          id="close-mask"
          x="0"
          y="0"
          width="24"
          height="24"
          maskUnits="userSpaceOnUse"
        >
          <path
            style={{ fill: '#fff', fillRule: 'evenodd' }}
            d="M18.65,6.06l-.71-.71a.5.5,0,0,0-.7,0L12,10.59,6.76,5.35a.5.5,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.7L10.59,12,5.35,17.24a.5.5,0,0,0,0,.7l.71.71a.5.5,0,0,0,.7,0L12,13.41l5.24,5.24a.5.5,0,0,0,.7,0l.71-.71a.5.5,0,0,0,0-.7L13.41,12l5.24-5.24A.5.5,0,0,0,18.65,6.06Z"
          />
        </mask>
      </defs>
      <g>
        <polygon points="0 0 24 0 24 24 0 24 0 0" style={{ fill: 'none' }} />
        <path
          style={{ fill: 'currentColor', fillRule: 'evenodd' }}
          d="M18.65,6.06l-.71-.71a.5.5,0,0,0-.7,0L12,10.59,6.76,5.35a.5.5,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.7L10.59,12,5.35,17.24a.5.5,0,0,0,0,.7l.71.71a.5.5,0,0,0,.7,0L12,13.41l5.24,5.24a.5.5,0,0,0,.7,0l.71-.71a.5.5,0,0,0,0-.7L13.41,12l5.24-5.24A.5.5,0,0,0,18.65,6.06Z"
        />
        <g style={{ mask: 'url(#close-mask)' }}>
          <rect width="24" height="24" style={{ fill: 'currentColor' }} />
        </g>
      </g>
    </svg>
  </button>
);

/**
 * Close menu button
 */
export const Close = styled(CloseSVG)`
  ${buttonStyles};
`;
